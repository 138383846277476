import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useSelector, useDispatch } from 'react-redux'
import { getLanguage } from '../redux/myTrafficSlice';
import { setLanguage } from '../redux/myTrafficSlice';
import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';


const languageMapping = {
    "en-GB": [<span className="fi fi-gb fi"></span>, "English GB"],
    "en-US": [<span className="fi fi-us fi"></span>, "English US"],
    "it-IT": [<span className="fi fi-it fi"></span>, "Italiano"],
}

const languagesandMaps = Object.keys(languageMapping).map(function (key) {
    let temp_value = languageMapping[key]
    temp_value.push(key)
    return temp_value;
});

function LanguagePicker(props) {
    const current_language = useSelector(getLanguage)
    const Flag = languageMapping[current_language][0]
    const dispatch = useDispatch()

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenLanguageMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseLanguageMenu = () => {
        setAnchorElUser(null);
    };

    const handleSwitchLanguage = (language) => {
        dispatch(setLanguage(language))
        handleCloseLanguageMenu()
    }



    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Change Language">
                <IconButton onClick={handleOpenLanguageMenu} sx={{ p: 0 }}>
                    {Flag}
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseLanguageMenu}
            >
                <>
                    {languagesandMaps.map((info) => (
                        <MenuItem
                            key={info[1]}
                            onClick={(e) => handleSwitchLanguage(info[2])}
                            sx={{ my: 1, px: 1 }}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'row',
                                mx: "8px"
                            }}>
                                {info[0]}
                                <Typography textAlign="center" sx={{ paddingLeft: 2 }}>{info[1]}</Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </>
            </Menu>
        </Box>

    )
}

export default LanguagePicker