import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import { SvgIcon } from '@mui/material';
//scale(0.100000,-0.100000)
function LogoIcon(props) {
  return (
    <Box {...props}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="24.000000pt" height="24.000000pt" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#fff" stroke="none">
          <path d="M1215 3465 c-368 -68 -661 -358 -732 -722 l-18 -93 -72 0 -73 0 0 -90 0 -90 73 0 72 0 18 -93 c33 -171 108 -314 235 -448 172 -182 375 -274 627 -286 240 -10 455 65 644 225 177 151 311 428 311 646 0 76 17 123 61 171 107 116 291 116 398 0 44 -48 61 -95 61 -171 0 -165 80 -377 198 -526 136 -171 323 -285 542 -330 76 -16 119 -19 215 -15 256 12 452 102 626 285 128 135 203 278 236 449 l18 93 73 0 72 0 0 90 0 90 -72 0 -73 0 -18 93 c-33 171 -108 314 -235 448 -173 183 -373 274 -627 286 -239 10 -455 -65 -643 -224 -104 -89 -209 -243 -258 -378 -9 -27 -18 -51 -19 -53 -1 -1 -28 13 -60 32 -85 52 -149 71 -235 71 -86 0 -150 -19 -235 -71 -32 -19 -59 -33 -60 -32 0 2 -13 35 -28 74 -111 288 -365 502 -672 565 -104 22 -247 23 -350 4z m342 -150 c254 -59 474 -257 558 -505 73 -215 49 -448 -67 -645 -53 -90 -183 -220 -273 -273 -335 -196 -761 -115 -1001 191 -111 142 -164 295 -164 477 0 180 50 328 157 468 188 245 491 355 790 287z"/>
        </g>
      </svg>
    </Box>
  )
};


function ResponsiveAppBar(props) {
  return (
    <AppBar position="static" sx={{ bgcolor: "#031434", marginBottom: "20px" }} elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Box display={"flex"}>
            <LogoIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'Gilmer',
                fontWeight: 700,
                letterSpacing: '.2rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              MyTraffic Counter Smart DashBoard
            </Typography>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'Gilmer',
                fontWeight: 700,
                letterSpacing: '.2rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              MyTraffic
            </Typography>
          </Box>

          <Box sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: 'center'
          }}>
            {props.children}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;