import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user_logged_in: false,
    login_error: false,
    display_login_error: false,
    login_error_message: ""
  },
  reducers: {
    setLoginTrue: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state.user_logged_in = true
    },
    setLoginFalse: (state) => { state.user_logged_in = false },
    setLoginErrorTrue: (state) => { state.login_error = true },
    setDisplayLoginError: (state, action) => { state.display_login_error = action.payload },
    setLoginErrorMessage: (state, action) => { state.login_error_message = action.payload }

  }
})

export const {
  setLoginTrue,
  setLoginFalse,
  setLoginErrorTrue,
  setDisplayLoginError,
  setLoginErrorMessage } = loginSlice.actions

export const getLoginError = state => state.login.login_error.value
export const getDisplayLoginError = state => state.login.display_login_error
export const getLoginErrorMessage = state => state.login.login_error_message

export default loginSlice.reducer