import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {}
  },
  reducers: {
    setUserInfo: (state, userdata) => { state.user = { ...userdata.payload.user } }
  }
})

export const { setUserInfo } = userSlice.actions

export const getUserInfo = state => state.user.user


export default userSlice.reducer