import { createSlice } from '@reduxjs/toolkit'

//const dummy_data = {
//  config: {
//    selector: '#traffic-counter-component',
//    data: {
//      doors: [{ id: "1", label: "1 - MYLUXOTTICA DUMMY TEST" }, { id: "2", label: "2 - MYLUXOTTICA DUMMY TEST" }],
//      lang: "en-US",
//      apiBase: 'http://localhost:8000/'
//    },
//    window_position: window.MyTrafficCounter
//  }
//}

export const myTrafficSlice = createSlice({
  name: 'myTraffic',
  initialState: {
    config: {
      selector: '#traffic-counter-component',
      data: {
        doors: [],
        lang: "en-US",
        apiBase: ''
      },
      window_position: window.MyTrafficCounter
    }


  },
  reducers: {
    setMyTrafficConfig: (state, user_details) => {
      let door_access_list = user_details.payload.user.door_access_list
      let doors_info = []
      for (let i = 0; i < door_access_list.length; i++) {
        doors_info.push({
          id: door_access_list[i],
          label: "Store ID: " + door_access_list[i]
        })
      }

      state.config.data.doors = doors_info
    },
    setLanguage: (state, language) => {
      state.config.data.lang = language.payload
    }

  }

})

export const {
  setMyTrafficConfig,
  setLanguage } = myTrafficSlice.actions

export const getConfig = state => state.mytraffic.config
export const getLanguage = state => state.mytraffic.config.data.lang
export const getMytrafficScript = state => state.mytraffic.window_position

export default myTrafficSlice.reducer