import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useNavigate } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import ResponsiveAppBar from './NavBar';

function LogoutPage(props) {
    const navigate = useNavigate()

    const RedirectToLogin = (event) => {
        event.preventDefault();
        navigate('/login');
    }

    return (
        <>
            <ResponsiveAppBar></ResponsiveAppBar>
            <Box>
                <Container component="main" maxWidth="xs">
                    <Card sx={{
                        borderRadius: 5, display: 'flex',
                        py: 2,
                        flexDirection: 'column', alignItems: 'center',
                    }}>
                        <Typography variant='h3'>Logout</Typography>
                        <Box py={5}>

                            <Typography variant='h5'>user successfully logged out</Typography>
                        </Box>
                        <Box display={"flex"}>
                            <Button
                                onClick={RedirectToLogin}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: '#091F5A' }}
                            >
                                Log In Again ?
                            </Button>
                        </Box>

                    </Card>
                </Container>

            </Box>
        </>
    )

}

export default LogoutPage