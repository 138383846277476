import './styles/App.css';
import { useEffect } from 'react';
import NavBar from './components/NavBar';
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import MyTrafficCounterComponent from './components/MyTraffic'
import { Box } from '@mui/material';
import { setUserInfo } from './redux/userSlice';
import { setMyTrafficConfig } from './redux/myTrafficSlice';
import UseapiInstance from './utils/axios'
import { useDispatch } from 'react-redux'
import LangPicker from './components/LanguagePicker';
import UserChip from './components/UserChip';

function App() {
    const [token, setToken, removeToken] = useCookies(['sessionid'])
    const sessionToken = token['sessionid']
    const csrfToken =  token['csrftoken']

    let navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {

        if (sessionToken === undefined) {
            navigate('/login')
        }
        else {
            UseapiInstance.get(
                "api/v1/user",
                {
                    headers: { "X-CSRFToken": csrfToken },
                    withCredentials: true
                }
            ).then(function (response) {
                dispatch(setUserInfo(response.data))
                dispatch(setMyTrafficConfig(response.data))
            }
            )
        }

    }, [dispatch, navigate, sessionToken, csrfToken])


    return (

        <div className="App">
            <NavBar>
                <LangPicker />
                <UserChip></UserChip>
            </NavBar>
            <Box sx={{
                bgcolor: "white",
                paddingBottom: "36px",
                marginBottom: "36px",
                borderRadius: "44px",
                textAlign: "initial",
                width: "78%"
            }}>

                <MyTrafficCounterComponent setToken />
            </Box>
        </div>
    );
}

export default App;
