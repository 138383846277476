import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux'
import { getUserInfo } from '../redux/userSlice';
import { Divider } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import UseapiInstance from '../utils/axios'
import { useNavigate } from 'react-router-dom';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(user) {
    let chars = ''
    if (user.name && user.surname) {
        chars = `${user.name[0].toUpperCase()}${user.surname[0].toUpperCase()}`
    }
    else {
        chars = `${user.username[0].toUpperCase()}${user.username[1].toUpperCase()}`
    }

    return {
        sx: {
            bgcolor: stringToColor(chars),
        },
        children: chars,
    };
}

function UserChip(props) {
    let navigate = useNavigate()
    const user = useSelector(getUserInfo)
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [loading, setLoading] = React.useState(false);


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogOut = (event) => {
        setLoading(true)
        UseapiInstance.post(
            "api/v1/logout"
        ).then(() => {
            setLoading(false)
            handleCloseUserMenu()
            navigate('/logout');
        })
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box sx={{ flexGrow: 0, paddingLeft: "20px" }}>
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        {Object.keys(user).length === 0 && user.constructor === Object ? <></> :
                            <Avatar {...stringAvatar(user)} />}
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{
                        mt: '45px',
                    }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {Object.keys(user).length === 0 && user.constructor === Object ?
                        <></>
                        :
                        <Typography variant='h6' textAlign="center" sx={{ p: 2 }}>Welcome {user.name ? user.name : user.username}</Typography>
                    }
                    <Divider></Divider>
                    <MenuItem key={'logout'} onClick={handleLogOut}>
                        <Typography textAlign="center">Log out</Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </>
    )
}

export default UserChip