import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import UseapiInstance from '../utils/axios'
import { useSelector, useDispatch } from 'react-redux'

import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import {
    getDisplayLoginError,
    setLoginErrorMessage,
    setDisplayLoginError,
    getLoginErrorMessage
} from '../redux/loginSlice';
import ResponsiveAppBar from './NavBar';
import { Card } from '@mui/material';

function TransitionAlerts(props) {
    const display_error = useSelector(getDisplayLoginError)
    const error_message = useSelector(getLoginErrorMessage)
    const dispatch = useDispatch()
    return (
        <Box sx={{ width: '100%', height: display_error === true ? '100%' : '0%' }}>
            <Collapse in={display_error}>
                <Alert
                    variant="filled"
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(setDisplayLoginError(false));
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {error_message}
                </Alert>
            </Collapse>
        </Box>
    );
}

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://essilorluxottica.com">
                EssilorLuxottica
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function SignIn() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false)

    const handleSubmit = (event) => {
        setLoading(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        UseapiInstance.post(
            "api/v1/login",
            data
        ).then(() => {
            setLoading(!loading)
            navigate('/');
        })
            .catch(function (error) {
                setLoading(false)

                if (error.response) {
                    dispatch(setLoginErrorMessage(error.response.data[0]))
                } else if (error.request) {
                    dispatch(setLoginErrorMessage("Service Currently Unavailable"))

                } else {
                    // Something happened in setting up the request that triggered an Error
                    dispatch(setLoginErrorMessage("Unamanaged Error, please Contact Support"))
                }
                dispatch(setDisplayLoginError(true))
            });


    };

    return (
        <>
            <ResponsiveAppBar></ResponsiveAppBar>
            <Container component="main" maxWidth="xs">
                <Card sx={{ px: 5, borderRadius: 5 }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: '#091F5A' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            MyTraffic Counter Log in
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} Validate sx={{ mt: 1 }}>
                            <TransitionAlerts />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: '#091F5A' }}
                            >
                                Sign In
                            </LoadingButton>
                            <Grid container>
                                {/*    <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
                        </Grid>*/}
                            </Grid>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Card>
            </Container>
        </>
    );
}