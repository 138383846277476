import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import loginReducer from './loginSlice'
import myTrafficReducer from './myTrafficSlice'

export default configureStore({
  reducer: {
    user: userReducer,
    login: loginReducer,
    mytraffic: myTrafficReducer
  }
})