import React from "react"
import { useEffect } from 'react';
import $ from 'jquery'
//import { getUserInfo } from "../redux/userSlice";
import { useSelector } from 'react-redux'
import { getConfig } from "../redux/myTrafficSlice";
import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";


const applyCustomStyling = () => {
    $(".store-button-submit ").detach().insertAfter('.dropdown-button-submit ')
    $(".page-store-wrapper").detach()
    $('#traffic-counter-component').show()

    $('.page-title-wrapper').css("border-top-left-radius", "44px");
    $('.page-title-wrapper').css("border-top-right-radius", "44px");
  
    $(".filter-container").css("display", 'flex')
    $(".filter-container").css("flexDirection", 'row')
    $(".filter-container").css("backgroundColor", 'var(--light-background)')
    $(".filter-container").css("justifyContent", 'space-between')
    $(".filter-container").css("width", '100%')
    $(".filter-container").css("flexWrap", 'wrap')
    $(".filter-container").css("alignItems", 'center')
    $(".dropdown-title").css("background-color", "white")
}


function MyTrafficCounterComponent(props) {
    //const user_info = useSelector(getUserInfo)
    const config = useSelector(getConfig)
    //const banana = useSelector(getMytrafficScript)
    //console.log(banana)
    const [scriptLoaded, SetscriptLoaded] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [MyTrafficCounter, MyTrafficFetched] = React.useState(window.MyTrafficCounter)


    useEffect(() => {
        $('#traffic-counter-component').hide();
        setLoading(true)

        const script = document.createElement('script');
        script.src = 'https://lomytrafficsad01.blob.core.windows.net/whs-standalone/TrafficCounter.min.js?sp=r&st=2023-10-02T08:17:34Z&se=2043-03-01T17:17:34Z&sv=2022-11-02&sr=c&sig=65BvkwAe6yXDIuiyjAy6prGvap%2FETr8ehElD9q8agT8%3D';
        script.async = false;
        document.body.appendChild(script);
        setTimeout(() => {
            applyCustomStyling()
        }, 1000);

        SetscriptLoaded(true)

        return () => {
            document.body.removeChild(script);
        }
    }, []);


    useEffect(() => {
        if (scriptLoaded === true && window.MyTrafficCounter !== undefined) {
            $('#traffic-counter-component').hide();
            setLoading(true)
            var Component = window.MyTrafficCounter.Component.new(config);

            setTimeout(() => {
                applyCustomStyling()
                setLoading(false)
            }, 1000);

            Component.render()

        }

    }, [scriptLoaded, config, MyTrafficCounter])


    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div id="traffic-counter-component"></div>
        </>
    )
}

export default MyTrafficCounterComponent



